import React from 'react';
import { Link } from 'react-router-dom';
import voiceBunner from '../img/voice.png';

function Home() {
  return (
    <div>
      <h2>お知らせ</h2>
        <div className="top_box">
          <h4 className="h4_info">アスパス休館日に伴うお休みのお知らせ</h4>
          <p className='home_maininfo'><time className='closed_days' datetime="2025/2/23">2025年2月23日</time>～<time className='closed_days' datetime="2025/2/26">2025年2月26日</time>までお休みいたします。</p>
        </div>
      <h2>ネクストキャリアセンターあおもりとは</h2>
        <div className="top_box">
            <p className="maininfo">「ネクストキャリアセンターあおもり」は、45歳以上の方を対象として、個々の職業適性や能力、経験、価値観などを踏まえて、職業選択や就職活動に関するアドバイスなどを行うキャリアカウンセリングを実施しています。また、各種就職情報の提供や再就職支援セミナーの開催などを通じて、個々の特性に応じたきめ細かい就業支援を行います。 </p>
            <p className="left_link"><Link to="/Contact">お問い合せへ</Link></p>
        </div>
        <br />
        <h2>就職相談について</h2>
        <div className="top_box">
          <p className="maininfo">
              ネクストキャリアセンターあおもりでは、就職相談のプロであるキャリアカウンセラーが、「個人が、その適性や職業経験等に応じて自ら職業生活設計を行い、これに即した職業選択や職業訓練等の職業能力開発を効果的に行うことができるよう個別の希望に応じて実施される相談その他の支援」をいたします。
              就職に関することや、職場定着に関する相談など幅広く受付しておりますので、お気軽にお問い合わせください。
          </p>
          <p className="left_link"><Link to="/CareerCounseling">就職相談実施内容</Link></p>
        </div>
        <br />
        <h2>再就職支援セミナーとは</h2>
        <div className="top_box">
          <p className="maininfo">応募書類の書き方、面接対策、資格取得、今後のキャリア及びライフプラン等についてなど、再就職活動に必要な能力を身に付けるための各種セミナーです。<br /> 県内各地域（青森市・弘前市・八戸市）にて開催いたします。</p>
          <p className="left_link"><Link to="/Seminar">再就職支援セミナー実施日程</Link></p>
        </div>
        <br />
        <h2>利用者の声</h2>
        <img src={voiceBunner} alt='ボイス' />
        </div>
  )
}

export default Home;
