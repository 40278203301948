import React from 'react'
import { Link } from 'react-router-dom';
import CareCounFlyer1 from '../img/2024/counseling2024front.png';
import CareCounFlyer2 from '../img/2024/counseling2024.png';
import ContactTel from '../img/20230623_counselingtel.png';
import ContactTel2 from '../img/counselingtel3.png';
function CareerCounseling() {
  return (
    <div>
        <h2>就職相談について</h2>
        <img className='flyer-image' src={CareCounFlyer1} alt="就職相談のチラシ（表面）" />
        <img className='flyer-image' src={CareCounFlyer2} alt="就職相談のチラシ（裏面）"/>
        <h3>出張相談</h3>
        <p>出張相談を承ります。各市で実施いたしますので、お気軽にご相談下さい。※要予約</p>
        <hr />
        <img className='contact-image' src={ContactTel} width="780" alt="就職相談の問合せ先" />
        <Link to="/contact"><img className='contact-image' src={ContactTel2} width="780" alt="就職相談の問合せ先（リンク付き）" /></Link>
    </div>
  )
}

export default CareerCounseling;
