import React from 'react';

function Access() {
  return (
    <div>
      <div id="privacy_contents">
                <h3>青森会場</h3>
                <dl>
                    <dt>■ ネクストキャリアセンターあおもり</dt>
                    <dd>〒030-0803 青森県青森市安方1丁目1番40号　青森観光物産館アスパム　7階</dd>
                    <dd>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.9277970852404!2d140.73870411540855!3d40.82954957931926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9b9ee1ba1fe42d%3A0x2bf0e7604a1c85fb!2z6Z2S5qOu55yM6Kaz5YWJ54mp55Sj6aSoIOOCouOCueODkeODoA!5e0!3m2!1sja!2sjp!4v1565402905087!5m2!1sja!2sjp" 
                            width="600" 
                            height="450"
                            title='aomoriMap'
                            style={{border: 'none'}} 
                            allowfullscreen>
                        </iframe>
                    </dd>
                </dl>
                <h3>八戸会場</h3>
                <dl>
                    <a href="https://www.tomonokai.jp/fukushi/">
                        <dt>■ 友の会福祉会館</dt>
                    </a>
                    <dd>〒031-0077 青森県八戸市長根1丁目2-8 </dd>
                    <dd>
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.464765150509!2d141.47865282241435!3d40.50921728173962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9b4df808e5cbe7%3A0xf53b8cd6cd8c56f1!2z44CSMDMxLTAwNzcg6Z2S5qOu55yM5YWr5oi45biC6ZW35qC577yR5LiB55uu77yS4oiS77yY!5e0!3m2!1sja!2sjp!4v1566522185381!5m2!1sja!2sjp"
                            width="600" 
                            height="450" 
                            style={{border: 'none'}}
                            title='hachinoheMap' 
                            allowfullscreen>
                        </iframe>
                    </dd>
                </dl>
                <h3>弘前会場</h3>
                <dl>
                    <a href="http://www.ims-hirosaki.com/toplink/access.html">
                        <dt>■キャリアスクールI・M・S 1階</dt>
                    </a>
                    <dd>〒036-8182 青森県弘前市土手町134-8</dd>
                    <dd>
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d757.3571669829587!2d140.47581722123658!3d40.59836491591862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9b01ee4952196b%3A0x4d56634264729a27!2z44CSMDM2LTgxODIg6Z2S5qOu55yM5byY5YmN5biC5aSn5a2X5Zyf5omL55S677yR77yT77yU4oiS77yY!5e0!3m2!1sja!2sjp!4v1589509951145!5m2!1sja!2sjp"
                        width="600"
                        height="450"
                        title='hirosakiMap'
                        style={{border: 'none'}}
                        tabIndex="0">
                        </iframe>
                    </dd>
                </dl>
            </div>
    </div>
  )
}

export default Access;