import React from 'react';
import { BrowserRouter as Router ,Route ,Routes} from 'react-router-dom';
import Header from './components/Header';  // ホームページ用コンポーネン
import Footer from './components/Footer';
import Side from './components/Side';
import Home from './components/Home';
import { Matching } from './components/Matching';
import Privacy from './components/Privacy';
import Contact from './components/Contact';
import CareerCounseling from './components/CareerCounseling';
import Seminar from './components/Seminar';
import Access from './components/Access';
import './css/Style.css';


function App(){
  return(
    <div>
      <Router>
      <Header />
      <div id="contents">
      <Side />
      <div id="main">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/matching" element={<Matching />} />
        <Route path="/seminar" element={<Seminar />} />
        <Route path="/careercounseling" element={<CareerCounseling />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/access" element={<Access />} />
      </Routes>
      </div>
      </div>
      <Footer />
      </Router>
    </div>
  )
}

export default App;