import React, { useState } from 'react';
import SelectAge from './SelectAge/SelectAge';

function Contact() {
  const [formData, setFormData] = useState({
    simei: '',
    furigana: '',
    seibetu: '男性', // デフォルトで男性が選択されている
    nenrei: '',
    address: '',
    email: '',
    email2: '',
    tel: '',
    message: ''
  });

  const [sendStatus, setSendStatus] = useState(null); // 送信の成否を管理
  const [isSubmitted, setIsSubmitted] = useState(false); // フォームの送信状態を管理

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // メールアドレスの確認
    if (formData.email !== formData.email2) {
      alert('メールアドレスが一致しません。');
      return;
    }

    try {
      const response = await fetch('inquiry2.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(formData).toString()
      });

      const result = await response.json();

      if (result.success) {
        setSendStatus(true); // 送信成功
        setIsSubmitted(true); // フォームを非表示にする
      } else {
        setSendStatus(false); // 送信失敗
      }
    } catch (error) {
      console.error('Error:', error);
      setSendStatus(false); // エラー時も失敗とみなす
    }
  };

  return (
    <React.Fragment>
      <h2>お問合せ</h2>
      <div className="top_box">
        <p className="maininfo">下記必要事項を入力の上、送信ボタンをクリックしてください。</p>
        <p className="left_link"></p>
      </div>
      <div>
        {!isSubmitted ? (
          <form onSubmit={handleSubmit}>
            <dl>
              <dt className="dflo">
                <label htmlFor="simei">
                  <p className="maininfo1">氏名<span className="red1">※</span></p>
                </label>
              </dt>
              <dd className="dflo">
                <input
                  name="simei"
                  type="text"
                  value={formData.simei}
                  onChange={handleChange}
                  size="25"
                  maxLength="255"
                  required
                  className="chkrequired"
                />
              </dd>
              <dt>
                <label htmlFor="furigana">
                  <p className="maininfo1">ふりがな</p>
                </label>
              </dt>
              <dd>
                <input
                  name="furigana"
                  type="text"
                  value={formData.furigana}
                  onChange={handleChange}
                  size="25"
                  maxLength="255"
                />
              </dd>
              <dt>
                <p className="maininfo1">性別</p>
              </dt>
              <dd className='genderSelect'>
                <label className='gender' htmlFor="seibetum">
                  <input
                    className='genderRadio'
                    id="seibetum"
                    type="radio"
                    name="seibetu"
                    value="男性"
                    checked={formData.seibetu === '男性'}
                    onChange={handleChange}
                  />
                  <span className="radio genderText">男性</span>
                </label>
                <label className='gender' htmlFor="seibetuw">
                  <input
                    className='genderRadio'
                    id="seibetuw"
                    type="radio"
                    name="seibetu"
                    value="女性"
                    checked={formData.seibetu === '女性'}
                    onChange={handleChange}
                  />
                  <span className="radio genderText">女性</span>
                </label>
              </dd>
              <dt>
                <p className="maininfo1">年齢</p>
              </dt>
              <dd>
                <SelectAge minAge={45} maxAge={75} />
              </dd>
              <dt>
                <label htmlFor="address">
                  <p className="maininfo1">お住いの市町村</p>
                </label>
              </dt>
              <dd>
                <input
                  name="address"
                  type="text"
                  value={formData.address}
                  onChange={handleChange}
                  size="30"
                  maxLength="255"
                />
              </dd>
              <dt id="mailadd">
                <label htmlFor="email">
                  <p className="maininfo1">メールアドレス<span className="red1">※</span></p>
                </label>
              </dt>
              <dd>
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  size="30"
                  maxLength="255"
                  required
                />
              </dd>
              <dt id="kaku">
                <label htmlFor="email2">
                  <p className="maininfo3">メールアドレス確認<span className="red1">※</span></p>
                </label>
              </dt>
              <dd>
                <input
                  name="email2"
                  type="email"
                  value={formData.email2}
                  onChange={handleChange}
                  size="30"
                  maxLength="255"
                  required
                />
              </dd>
              <dt>
                <label htmlFor="tel">
                  <p className="maininfo1">電話番号<span className="red1">※</span></p>
                </label>
              </dt>
              <dd>
                <input
                  name="tel"
                  type="text"
                  value={formData.tel}
                  onChange={handleChange}
                  size="30"
                  maxLength="255"
                  required
                />
              </dd>
              <dt id="inquiry">
                <p className="maininfo1">相談内容</p>
              </dt>
              <dd>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  cols="55"
                  rows="15"
                />
              </dd>
            </dl>
            <p className="subcen">
              <button type="submit" id='sousin'>送信</button>
            </p>
          </form>
        ) : (
          <p>お問い合わせありがとうございました。送信が完了しました。</p>
        )}
        {sendStatus === false && <p>メールの送信に失敗しました。</p>}
      </div>
    </React.Fragment>
  );
}

export default Contact;
