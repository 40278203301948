import React from 'react';
// import DynamicTable from './DynamicTable/DynamicTable';
import DynamicTable2 from './DynamicTable/DynamicTable2';
import matchingFlyer from '../img/2024/matching2024.png';
// import {Link, Element} from 'react-scroll';


export const Matching =() => {
  return (
    <div>
            <h2>合同企業説明会日程</h2>
            {/* <img className='matchingFlyer' src={matchingFlyer} alt="合同企業説明会チラシ" /> */}
            <p>合同企業説明会の日程は終了いたしました。たくさんのご参加誠にありがとうございました。</p>
            {/* <div className="h3-row">
              <h3 className='h3-companyList'>参加企業一覧（青森会場）</h3>
              <p className='atentionText'>※参加事業所・主な募集職種はチラシ作成時のものです。<br />変更となる場合もございますので予めご了承ください。</p>
              <Link style={{cursor:'pointer'}} to="hachinohe" smooth={true} duration={500}>八戸会場はこちら</Link>
            </div>
            <DynamicTable />
            <p className='upDate'>2024/10/17更新</p>
            <div style={{marginTop:'50px'}}></div> */}
            {/* <Element name="hachinohe" className="h3-row"> */}
              {/* <h3 className='h3-companyList'>参加企業一覧（八戸会場）</h3>
              <p className='atentionText'>※参加事業所・主な募集職種はチラシ作成時のものです。<br />変更となる場合もございますので予めご了承ください。</p> */}
            {/* </Element> */}
            {/* <DynamicTable2 />
            <p className='upDate'>2024/10/17更新</p> */}
    </div>
  );
};
