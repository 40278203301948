import React from 'react';
import { createRoot } from 'react-dom/client'; // これが正しい
// import 'bootstrap/dist/css/bootstrap.min.css';  // BootstrapのCSSをインポート
import './App.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );