    import React from 'react';
    import '../css/Style.css';
    import { Link } from 'react-router-dom';
    function Footer() {
      return (
        <div>
            <footer id="foot">
              <div id="foot_content">
                  <p>ネクストキャリアセンターあおもり　運営は、青森県から委託を受け、株式会社I・M・Sが実施しています。</p>
                  <h3>株式会社I・M・S</h3>
                  <p>本社：<Link className='ims-link' to="/access">青森県弘前市土手町134-8</Link><br />
                  TEL：0172-32-5801　FAX：0172-32-5801<br />
                  本社ホームページ<br />
                  <a className='ims-link' href="https://ims-hirosaki.com/">https://ims-hirosaki.com/</a><br /></p>
                  <p><small>Copyright 2023 I・M・S All Rights Reserved.</small></p>
              </div>
            </footer>
        </div>
      )
    }
    
    export default Footer;
    

