import React from 'react'
import { Link } from 'react-router-dom';

function Header() {
  return (
      <header>
        <div id="top_feed">
            <div id="feed_box">
            </div>
        </div>
        <nav id="nav">
            <ul>
                <li><Link to="/">ホーム</Link></li>
                <li><Link to="/matching">合同企業説明会</Link></li>
                <li><Link to="/seminar">再就職支援セミナー</Link></li>
                <li><Link to="/careercounseling">キャリアカウンセリング</Link></li>
                <li><Link to="/contact">お問合せ</Link></li>
                <li><Link to="/privacy">個人情報保護協定</Link></li>
            </ul>
        </nav>
    </header>
  );
}

export default Header;
