
       import React from 'react'
       import { Link } from 'react-router-dom';
       import '../css/Style.css';
       import payFree from '../img/001.png';
       import mailBauner from '../img/banner2.png';
       import accessBauner from '../img/access.png';

       function Side() {
         return (
           <div>
                <div id="side">
                  <img src={payFree} alt="ご利用無料" />
                    <Link to="/contact"><img src={mailBauner} alt="メール相談もできます" /></Link>
                    <div className="area">
                        <h4>住所</h4>
                        <p>青森県青森市安方1丁目1番40号<br />青森観光物産館アスパム7階</p>
                        <h4>TEL/FAX</h4>
                        <p>TEL:017-723-6350
                            <br />FAX:017-777-4660
                        </p>
                        <h4>利用時間</h4>
                        <p>9：00～16：00 <br />
                        平日および第2・第4土曜日<br />
                        （祝日・年末年始・アスパム休館日除く）</p>
                    </div>
                    <div className="accessBauner">
                      <Link to="/access"><img src={accessBauner} alt="会場アクセス" /></Link>
                    </div>
                </div>
           </div>
         );
       }
       
       export default Side;
          
