import React from 'react';
import '../css/Style.css';

function Privacy() {
  return (
         <div>
            <h2>ネクストキャリアセンターあおもり個人情報保護規程</h2>
                <div className="top_box">
                    <p className="maininfo">ネクストキャリアセンターあおもりは、事業遂行においてお預かりするご利用者様及びそのご家族その他関係各所の皆様(以下併せて「利用者様等」といいます)の個人情報について、厳正に保護することを社会的責任と認識し、以下に掲げる個人情報保護規程に従い個人情報の保護に努めてまいります。
                    </p>
                </div>
                <div id="privacy_contents">
                    <h3>第１条　個人情報の取得と利用</h3>
                    <p>ネクストキャリアセンターあおもりの事業内容を考慮し、利用目的を明確にした上で、目的の範囲内に限り、個人情報を取得します。また、取得した個人情報は、その利用目的から逸脱しない範囲で確実に利用するため、従業者教育を徹底いたします。</p>
                    <h3>第２条　個人情報の管理と保護</h3>
                    <p>個人情報の管理は、厳重に行うこととし、利用者様等にご承諾いただいた場合を除き、第三者に対し開示・提供することはいたしません。また個人情報に関する不正アクセス、紛失、破壊、改ざん、漏洩を防ぐための適切な処置を行います。また、万一の際には速やかに是正処置を講じます。</p>
                    <h3>第３条　準拠法等</h3>
                    <p>ネクストキャリアセンターあおもりは、当センターが保有する個人情報に関して適用される法令、国が定める指針その他規範を遵守いたします。</p>
                    <h3>第４条　個人情報保護管理体制及び仕組みの継続的改善</h3>
                    <p>ネクストキャリアセンターあおもりは、個人情報保護に関する管理の体制と仕組みなど、個人情報保護マネジメントシステムについて継続的改善を実施いたします。</p>
                    <h3>第５条　個人情報に関する苦情・相談・お問い合わせ</h3>
                    <p>個人情報の取扱いに関する苦情及び相談に対しては、迅速かつ適切に対応いたします。また、個人情報の開示、利用目的の通知、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止のお申し出がなされた場合、ご本人であることを確認したうえで、合理的な期間、法令等に定められた範囲内で対応いたします。これらに関するお問い合わせは、下記宛にお願いいたします。</p>
                </div>
            </div>
  )
}

export default Privacy;