import React from 'react'

function Seminar() {
  return (
    <div>
        <h2>再就職支援セミナー日程</h2>
        <p>再就職セミナーは日程を終了いたしました。</p>
    </div>
  )
}

export default Seminar;