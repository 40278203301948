import React from 'react';

const SelectAge = ({ minAge, maxAge }) => {
  const generateOptions = () => {
    let options = [];
    for (let i = minAge; i <= maxAge; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    // 76歳以上の選択肢を追加
    options.push(
      <option key="76~" value="76~">
        76~
      </option>
    );
    return options;
  };

  return (
    <label htmlFor="nenrei">
      <select className='selectAge' name="num" id="nenrei">
        <option value="">-----</option>
        {generateOptions()}
      </select>
      歳
    </label>
  );
};

export default SelectAge;
